import React from "react"
const BlogCTA = props => {
    return (
        <div className="flex flex-row justify-center mx-auto">
          
        </div>
    )
}

export default React.memo(BlogCTA)
