import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BlogDetailsPage from "../../components/body/pages/blog/blog-details"
const BlogDetailsPageByParam = ({ serverData }) => {
    return (

        <Layout>
            <SEO
                title={serverData?.seoTitle} description={serverData?.seoDescription}
                noIndex={true}
                />
            <BlogDetailsPage {...serverData} />
        </Layout>
    )
}

export default BlogDetailsPageByParam

export async function getServerData(props) {
    const errorBody = {
        status: 500,
        props: { resultStatus: 500, isLoaded: true, seoTitle: "Page not found", seoDescription: "" }
    }
    try {
        const res = await fetch(`${process.env.BLOGCMS_URL}/api/blogs?filters[blogTitle][$eq]=${props.params.title}&populate[blogHeroImage][populate]=*&populate[blogContent][populate]=*&populate[linkedBlogs][populate]=*&populate[linkedBlogs][blogHeroImage]fields[0]=url&populate=*`);
        if (!res.ok) {
            return errorBody
        }
        const result = await res.json();
        if (result.data?.length > 0) {
            const resultData = result.data?.[0]?.attributes
            const imageUrl = resultData?.blogHeroImage?.data?.attributes
            delete resultData?.blogHeroImage
            return {
                status: 200,
                props: { isLoaded: true, id: result.data?.[0]?.id, ...resultData, image: imageUrl, resultStatus: 200, }
            }
        } else {
            return errorBody
        }
    } catch (error) {
        return errorBody
    }
}