import React from "react"
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import styled from "styled-components";

const BlogMarkdownEditorSection = (props) => {
    const { sectionTitle, sectionBody } = props;
    return (
        <BlogMarkdownEditorSectionStyled>
            <div className="flex flex-column blog-kuda-section--max mx-auto">
                <div className="kuda-section--inner flex justify-between align-center">

                    <div className="flex flex-column justify-center">
                        {sectionTitle && <h1 className="kuda-blog-subtitle color-black">
                            {sectionTitle}
                        </h1>}
                        {sectionBody && <ReactMarkdown className={'color-black markdown'} children={sectionBody} rehypePlugins={[rehypeRaw]} />
                        } </div>
                </div>
            </div>
        </BlogMarkdownEditorSectionStyled>
    )
}

export default BlogMarkdownEditorSection;

const BlogMarkdownEditorSectionStyled = styled.div`
    .markdown h2,
    .markdown p, .markdown ol, .markdown ul {
        padding-top: 20px
    }
    .markdown p strong{
        font-weight: 800
    }
    .markdown strong {
        font-weight: 800
    }
    .markdown ol, ul {
        list-style: auto;
        font-size: 1rem;
        line-height: 1.5;

        li{
            margin-left: 15px;
        }
    }
`