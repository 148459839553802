import styled from "styled-components";

export const BlogPageStyled = styled.div`
    @media only screen and (max-width: 991px) {
        .kuda-preview {
            display: block;
            padding: 50px; 
        }
    }
    .editor-list {
        li:not(:last-child) {
            margin-bottom: 1.14em;
        }
    }
     .related-blog-list {
        background: #FBFBFB;
        padding: 50px 0 92px 0;
        @media only screen and (max-width: 991px) {
            padding: 51px 0;
        }
        .kuda-blog-card-image {
            height: 135px !important;
        }            
        .kuda-press--heading {
            font-size: 15px
        }
    }

.card--heading {
    font-size: 1.25rem;
    margin-bottom: 15px;
}
@media only screen and (min-width: 768px){
    
    .card--heading {
        font-size: 1.10rem;
    }
}
    .blog-details-date {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 178.571% */
        text-transform: uppercase;
        
    }
    .blog-heading-title {
        font-family: Mulish;
        font-size: 36px;
        font-style: normal;
        font-weight: 900;
        line-height: 50px; /* 138.889% */
        
    }
        .blog-kuda-section--max,
       .blog-header-image--max{
       width: 95%;
       }
    @media (min-width:768px) {
       .blog-kuda-section--max,
       .blog-header-image--max {
         width: 60%;
         padding: 0
       }
    }
    .header--bottom {
        padding-top: 11px;
        padding-bottom: 11px;
        @media (min-width:768px) {        
            padding-top: 19px;
            padding-bottom: 19px;
        }
    }
    .blog-header-image--top {
        padding-top: 37px;
        @media (min-width:768px) {        
            padding-top: 45px;
        }
    }
    .blog-header {
        padding-bottom: 32px
    }
    .kuda-blog-section {
        padding-bottom: 20px
    }
    .kuda-blog-subtitle {
        font-family: Mulish;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 28px; /* 140% */
        padding-bottom: 10px
    }
        p img,
      .blog-image img,
      .blog-section-image img {
        height: 100%;
        width: 100%;  
        object-fit: contain;
      }           
   
`

export const FilterSection = styled.div`
    padding-bottom: 10px;

    @media (min-width:768px) {
        padding-bottom: 20px;
    }
    .filter{
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 23px;
        padding-top: 23px;

    }
    div.filter:not(:first-child) {
        margin-left:43px;
    }
    .filter-divider {
        border: 1px solid #F9F9F9;
        background: #FFF;
    }
`