import React from "react"
const BlogContentSection = (props) => {
    const { sectionDescription, sectionTitle, sectionImage } = props;
    const imageData = sectionImage?.data
    const image = imageData?.attributes
    return (<div className="flex flex-column kuda-blog-section">
        <div className="kuda-section--inner flex justify-between align-center">

            <div className="flex flex-column mx-auto justify-center blog-kuda-section--max">

                <h1 className="kuda-blog-subtitle color-black">
                    {sectionTitle}
                </h1>
                {image && <div className="flex justify-center mx-auto title-bottom--spacing">

                    <div className="blog-section-image">
                        <img src={`${process.env.BLOGCMS_URL}${image?.url}`} alt={`${image.alternativeText? image.alternativeText: 'Kuda blog image'}`} />
                        {image?.caption && <div className="flex flex-row color-default mx-auto text-center p-1 p-md-4">
                            {`Image caption: ${image?.caption}`}
                        </div>
                        }
                    </div>
                </div>}

                <p className="color-black text-left">
                    {sectionDescription}
                </p>
            </div>
        </div>
    </div>
    )
}

export default BlogContentSection;
