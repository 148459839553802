import React from "react"
import styled from "styled-components";

const BlogVideoSection = (props) => {

    const { url } = props;
    return (
        <BlogVideoSectionStyled>
            <div className="flex flex-column blog-kuda-section--max mx-auto">
                <div className="kuda-section--inner flex justify-between align-center">

                    <div className="blog-video-container mx-auto">
                        <iframe
                            width="848"
                            height="400"
                            src={url}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        >
                        </iframe>
                    </div>
                </div>
            </div>
        </BlogVideoSectionStyled>
    )
}

export const BlogVideoSectionStyled = styled.div`
 

.blog-video-container {
    margin-top: 20px;
    width: 100%;
    height: 400px;
}

.blog-video-container iframe {
    width: 100%;
    height: 100%;
    min-height: 0px;
}
      
`

export default BlogVideoSection;
