
export function formatBlogDetails(props) {
    const blog = {
      blogTitle: props.blogTitle,
      blogSubtitle: props.blogSubtitle,
      publishedDate: props.publishedDate,
      publication: props.publication,
      image: {
        url: props.image?.url,
        caption: props.image?.caption
      },
    }
    const relatedBlogs = props?.linkedBlogs?.data?.map(a => {
      const blogImage = a.attributes?.blogHeroImage?.data
      return {
        blogTitle: a.attributes.blogTitle,
        blogSubtitle: a.attributes.blogSubtitle,
        publication: a.attributes.publication,
        publishedDate: a.attributes.publishedDate,
        image: blogImage ? {
          url: blogImage.attributes?.url
        } : null
      }
    })
    return { blogDetails: blog, relatedBlogs: relatedBlogs ?? [], blogBody: props?.blogContent ?? [], blogVideo: props?.blogVideo ?? {} }
  }
  export function formatLinkedBlogs(arr) {
    return arr.map(a => {
      return {
        blogTitle: a.blogTitle,
        subTitle: a.subTitle,
        seoTitle: a.seoTitle,
        seoDescription: a.seoDescription,
        image: {
          url: a.blogHeroImage.url,
          caption: a.blogHeroImage.caption
        },
      }
    })
  }