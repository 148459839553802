import React, { useEffect, Fragment } from "react"
import CTA from "../../general/cta"
import { scrollToElement } from "../../../../utility/utils"
import { useState } from "react"
import BlogHeader from "./blog-header"
import BlogContentSection from "./blog-content-section"
import BlogDetailsErrorPage from "./blog-details-error-page"
import { formatBlogDetails } from "./helper-utility"
import BlogMarkdownEditorSection from "../components/blog-markdown-editor-section"
import BlogRichEditorBlocksSection from "../components/blog-rich-editor-blocks-section"
import BlogVideoSection from "./blog-video-section"
import { BlogPageStyled } from "../styles/blogPageStyle"
import { BlogListCards } from "../components/blog-list-cards"
import Cta from "../../general/cta"
import BlogCta from "../components/blog-cta"
const BlogDetailsPage = (props) => {
  const [state, setState] = useState({
    isLoaded: false,
    hasError: false,
    relatedBlogs: [],
    blogDetails: {},
  })

  useEffect(() => {
    if (props.resultStatus === 200) {
      const blog = formatBlogDetails(props)
      setState({
        ...state,
        isLoaded: true,
        hasError: false,
        blogDetails: blog.blogDetails,
        relatedBlogs: blog.relatedBlogs,
        blogBody: blog.blogBody,
      })
    }
    else {
      setState({
        ...state,
        isLoaded: true,
        hasError: true,
        blogDetails: null,
        relatedBlogs: [],
        blogBody: null,
      })
    }

  }, [props])
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      {state.hasError ? <BlogDetailsErrorPage /> :
        <>
          <BlogHeader {...state.blogDetails} />

            <BlogPageStyled className="container">
              <div className="flex flex-column">
                {state.blogBody?.map((listItemContent, index) => {
                  switch (listItemContent.__component) {
                    case "section.blog-markdown-editor":
                      return <BlogMarkdownEditorSection {...listItemContent} key={index} />
                    case "section.blog-text-paragraph":
                      return <BlogContentSection {...listItemContent} key={index} />
                    case "section.blog-rich-editor-block":
                      return <BlogRichEditorBlocksSection {...listItemContent} key={index} />

                    case "section.blog-video-block":
                      return <BlogVideoSection {...listItemContent.videoMedia} />
                    default:
                      break;
                  }


                })}


                <BlogCta />

                {state?.relatedBlogs?.length > 0 &&
                  <div className="related-blog-list kuda-ignore--container">
                    <div className="container">
                      <div className="flex flex-column blog-kuda-section--max mx-auto">
                        <div className="color-black text-center text-xlbold">Read this next</div>
                        <BlogListCards blogs={state.relatedBlogs} headingColor={'color-black'} />
                      </div>
                    </div>

                  </div>
                }

              </div>
            </BlogPageStyled>
        </>
      }



      <CTA />
    </Fragment>
  )
}

export default BlogDetailsPage
