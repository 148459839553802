import React from "react"
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styled from "styled-components";
const BlogRichEditorBlocksSection = (props) => {

    const { title, sectionBody } = props;
    return (
        <BlogEditorBlocksStyled>
            <div className="flex flex-column blog-kuda-section--max mx-auto">
                <div className="kuda-section--inner flex justify-between align-center">

                    <div className="flex flex-column justify-center">
                        {title && <h1 className="kuda-blog-subtitle color-black">
                            {title}
                        </h1>}
                        {sectionBody &&    <BlocksRenderer content={sectionBody}
                            blocks={{
                                heading: ({ children, level }) => {
                                    const CustomTag = `h${level}`;
                                    return <CustomTag className="color-black mb-3">{children}</CustomTag>
                                },
                                paragraph: ({ children }) => <p className="color-black kuda-blog-section">{children}</p>,
                                link: ({ children, url }) => <a className="link-decoration color-primary" href={url}>{children}</a>,
                                list: ({ children }) => {
                                    return <span className="px-3 color-black mb-2 text-left editor-list">{children}</span>
                                },
                                image: ({ image }) => {
                                    return <>
                                        <img
                                            src={`${image?.url}`}
                                            width={image.width}
                                            height={image.height}
                                            alt={`${image.alternativeText ? image.alternativeText : 'Kuda blog image'}`} />
                                        {image?.caption && <div className="flex flex-row color-default mx-auto text-center p-1 p-md-4">
                                            {`Image caption: ${image?.caption}`}
                                        </div>
                                        }
                                    </>
                                }
                            }}
                            modifiers={{
                                bold: ({ children }) => <div className="text-bold">{children}</div>,
                                italic: ({ children }) => <span className="text-italic">{children}</span>,
                            }}

                        />}
                     
                    </div>
                </div>
            </div>
        </BlogEditorBlocksStyled>
    )
}

export const BlogEditorBlocksStyled = styled.div`
    .editor-list {
        li:not(:last-child) {
            margin-bottom: 1.14em;
        }
    }

@media only screen and (min-width: 768px) and (max-width: 1024px){    
        p img,
      .blog-image img,
      .blog-section-image img {
        height: 100%;
        width: 100%;  
        object-fit: contain;
      }           
      
`

export default BlogRichEditorBlocksSection;
